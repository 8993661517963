import React from "react";
import styles from "./styles";
import { theme } from "../../config/theme";
import BarLoader from "react-spinners/BarLoader";

const {
  colors: { Blue_3 },
} = theme;

const Loader = (props: IProps) => {
  return (
    <>
      <div style={{ height: props.height }} className="loader">
        <BarLoader color={props.color} />
      </div>
      {/*// @ts-ignore */}
      <style jsx={true}>{styles}</style>
    </>
  );
};

Loader.defaultProps = {
  height: "150px",
  color: Blue_3,
};

interface IProps {
  height?: string;
  color?: string;
}

export default Loader;
