import moment from "moment";
import ElementDropdown from "./ElementDropdown";
import { IAdminUser } from "../services/users";

export default function UsersTable({
  title = "Users",
  subTitle = "A list of all the users in selected tenant.",
  onAddUser,
  onEditUser,
  onDeleteUser,
  users = [],
}: {
  title?: string;
  subTitle?: string;
  onAddUser: () => void;
  onEditUser: (user: IAdminUser) => void;
  onDeleteUser: (username: string) => void;
  users?: {
    username: string;
    email: string;
    createdAt: string;
    role: string;
  }[];
}) {
  return (
    <div className="px-4 py-4 sm:px-6 lg:px-8 overflow-visible">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {title}
          </h1>
          <p className="mt-2 text-sm text-gray-700">{subTitle}</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={() => onAddUser()}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add user
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-visible shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Username
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Creation Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Resend Password</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {users &&
                    users.map((user) => (
                      <tr key={user.email}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {user.username}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {moment(user.createdAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.role}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <ElementDropdown
                            label="Edit"
                            buttonStyle="text-indigo-600 hover:text-indigo-900 disabled:text-gray-600"
                            actions={[
                              {
                                active: false,
                                onClick: () => onEditUser(user),
                                label: "Edit User",
                              },
                              {
                                active: false,
                                onClick: () => onDeleteUser(user.username),
                                label: "Delete User",
                              },
                            ]}
                          />
                          {/* <button
                            disabled={false}

                            className="text-indigo-600 hover:text-indigo-900 disabled:text-gray-600"
                          >
                            Edit
                            <span className="sr-only">, {user.username}</span>
                          </button> */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
