import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import cn from "classnames";

const buttonStyleDefault =
  "inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50";

export default function ElementDropdown({
  buttonStyle,
  label = "Options",
  actions,
}: {
  label: string;
  buttonStyle?: string;
  actions: { label: string; onClick: () => void; active: boolean }[];
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className={buttonStyle ?? buttonStyleDefault}>
          {label}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {actions.map((action) => (
              <DropdownItem
                active={action.active}
                onClick={action.onClick}
                label={action.label}
              />
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

const DropdownItem = ({
  active,
  onClick,
  label,
}: {
  active: boolean;
  onClick: () => void;
  label: string;
}) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          onClick={onClick}
          className={cn(
            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
            "block px-4 py-2 text-sm"
          )}
        >
          {label}
        </button>
      )}
    </Menu.Item>
  );
};
