import React from "react";

export default () => (
  <svg width="129" height="27" viewBox="0 0 129 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M119.144 6.75L115.698 10.9539L106.85 0H102.784L113.665 13.4065L108.014 20.2755H112.096L115.698 15.9186L124.934 27H129L117.731 13.4065L123.201 6.75H119.144Z"
        fill="#2A4B9A"
      />
      <path d="M129 27H124.934L115.698 15.9144L112.101 20.25H108.035L113.665 13.4065L102.784 0H106.85L115.698 10.9539L119.14 6.75H123.205L117.731 13.4065L129 27Z" fill="#4686FA" />
      <path d="M105.247 23.6377L102.48 26.9999H106.546L109.321 23.6377H105.247Z" fill="#2DC57C" />
      <path d="M125.984 3.36225L128.747 0H124.681L121.923 3.36225H125.984Z" fill="#2DC57C" />
      <path
        d="M71.929 6.75L67.1084 20.25H64.3966L61.2967 11.5277L58.1969 20.25H55.4597L50.6392 6.75H53.9752L56.8178 14.8134L59.6055 6.75H62.9922L62.9415 6.85627L65.7293 14.8134L68.6225 6.75H71.929Z"
        fill="white"
      />
      <path
        d="M89.1575 15.9781C89.1575 17.2023 88.7316 18.2182 87.8796 19.0301C87.0277 19.8419 85.9944 20.25 84.7798 20.25H76.91C75.6953 20.25 74.6621 19.8419 73.8101 19.0301C72.9582 18.2182 72.5322 17.198 72.5322 15.9781V11.0219C72.5322 9.7977 72.9582 8.7818 73.8101 7.96993C74.6621 7.15806 75.6953 6.75 76.91 6.75H84.7798C85.9944 6.75 87.0277 7.15806 87.8796 7.96993C88.7316 8.7818 89.1575 9.80195 89.1575 11.0219V15.9781ZM86.0282 15.9739V11.0261C86.0282 10.2738 85.6106 9.89972 84.7798 9.89972H76.91C76.0749 9.89972 75.6616 10.2738 75.6616 11.0261V15.9696C75.6616 16.722 76.0791 17.096 76.91 17.096H84.7798C85.6106 17.1003 86.0282 16.722 86.0282 15.9739Z"
        fill="white"
      />
      <path
        d="M106.2 20.25H101.928L95.2307 12.6839H103.282C103.855 12.6839 104.142 12.2206 104.142 11.2939C104.142 10.3673 103.855 9.89972 103.282 9.89972H93.5859V20.25H90.4565V6.75H103.459C104.657 6.75 105.606 7.22182 106.297 8.16971C106.938 9.02834 107.263 10.0697 107.263 11.2939C107.263 12.5181 106.943 13.5595 106.297 14.4181C105.602 15.3618 104.657 15.8378 103.459 15.8378H102.261L106.2 20.25Z"
        fill="white"
      />
      <path d="M15.1112 9.92534H9.10975V20.2034H6.00569V9.92534H0V6.79688H15.1112V9.92534Z" fill="#4686FA" />
      <path
        d="M32.5506 20.203H29.4466V15.9524H21.2689V12.8239H29.4466V10.9451C29.4466 10.2693 29.2231 9.92925 28.7718 9.92925H19.9783C19.5482 9.92925 19.3331 10.2693 19.3331 10.9451V20.2073H16.229V10.9409C16.229 9.81023 16.5791 8.84108 17.2749 8.02496C17.975 7.20884 18.8734 6.80078 19.9783 6.80078H28.776C29.8979 6.80078 30.8088 7.20884 31.5047 8.02496C32.2048 8.84108 32.5506 9.81023 32.5506 10.9409V20.203Z"
        fill="#4686FA"
      />
      <path
        d="M48.4925 20.2027H38.1934C36.9872 20.2027 35.9582 19.7989 35.1147 18.9913C34.2712 18.1837 33.8452 17.1763 33.8452 15.9606V11.0384C33.8452 9.82269 34.267 8.81104 35.1147 8.00342C35.9582 7.1958 36.9872 6.79199 38.1934 6.79199H48.4925V9.92046H38.1934C37.3668 9.92046 36.9535 10.2945 36.9535 11.0384V15.9478C36.9535 16.6917 37.3668 17.0658 38.1934 17.0658H45.3885V15.0595H41.171V11.931H48.4925V20.2027Z"
        fill="#4686FA"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="129" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
