export default function InputWithLabel({
  type,
  disabled = false,
  name,
  value,
  id,
  label,
  onChange,
  placeholder,
}: any) {
  return (
    <div className="mt-5">
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <input
          disabled={disabled}
          type={type}
          onChange={onChange}
          name={id}
          id={id}
          value={value}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 disabled:bg-gray-100 disabled:cursor-not-allowed placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
