import axios from "axios";
import env from "../config/api";

export type IAdminUser = {
  username: string;
  email: string;
  createdAt: string;
  role: string;
};

export const getAdminUsers = async (
  token: string
): Promise<{ data: IAdminUser[] }> => {
  return await axios.get(`${env.tenantBaseDomain}admin-users`, {
    headers: {
      authorization: `token=${token}`,
    },
  });
};

export const sendInvitationNewAdminUser = async (
  token: string,
  user: {
    username: string;
    email: string;
    role: string;
  }
) => {
  return await axios.post(`${env.tenantBaseDomain}admin-users`, user, {
    headers: {
      authorization: `token=${token}`,
    },
  });
};

export const deleteAdminUser = async (token: string, userId: string) => {
  return await axios.delete(`${env.tenantBaseDomain}admin-users/${userId}`, {
    headers: {
      authorization: `token=${token}`,
    },
  });
};

export const updateAdminUser = async (
  token: string,
  userId: string,
  data: { role: string }
) => {
  console.log(data);
  return await axios.put(
    `${env.tenantBaseDomain}admin-users/${userId}`,
    { role: data.role },
    {
      headers: {
        authorization: `token=${token}`,
      },
    }
  );
};
