import axios from "axios";
import env from "../config/api";
import { CreateTenantRequest } from "../types/tenant";

export const getTenants = async (token: string) => {
  return await axios.get(`${env.tenantBaseDomain}tenants`, {
    headers: {
      authorization: `token=${token}`,
    },
  });
};

export const getTenant = async (token: string, tenantId: string) => {
  return await axios.get(`${env.tenantBaseDomain}tenants/${tenantId}`, {
    headers: {
      authorization: `token=${token}`,
    },
  });
};

export const createTenant = async (
  createTenantRequest: CreateTenantRequest,
  token: string
) => {
  console.log({createTenantRequest})
  const resAxioscreate =  await axios.post(
    `${env.tenantBaseDomain}tenants`,
    createTenantRequest,
    {
      headers: {
        authorization: `token=${token}`,
      },
    }
  );
  console.log({resAxioscreate});
  return resAxioscreate;
};

export const deleteTenant = async (tenantId: string, token: string) => {
  return await axios.delete(`${env.tenantBaseDomain}tenants/${tenantId}`, {
    headers: {
      authorization: `token=${token}`,
    },
  });
};

export const updateTenantStatus = async (tenantId: string, token: string, status: "Active" | "Suspended" | "Deleted") => {
  console.log(tenantId)
  return await axios.patch(`${env.tenantBaseDomain}tenants/${tenantId}`, {
    status,
  }, {
    headers: {
      authorization: `token=${token}`,
    },
  });
};
