import React from "react";

export default () => (
  <svg width="18" height="18" viewBox="0 0 18 18">
    <path
      d="M15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25H3.75C2.925 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.925 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25ZM6.375 10.125L8.25 12.3825L10.875 9L14.25 13.5H3.75L6.375 10.125Z"
      fill="#A5ADBA"
    />
  </svg>
);
