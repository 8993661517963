import { useContext } from "react";
import { GeneralContext } from "../context/App";
import { Hub } from "aws-amplify/utils";
import { fetchAuthSession } from "aws-amplify/auth";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { WorxIcon } from "../icons";
import Dashboard from "../container/Dashboard";

export default function IsLogin() {
  const { generalContext, setGeneralContext } = useContext(GeneralContext);
  const { route } = useAuthenticator((context) => [context.route]);

  if (!generalContext.token && !!localStorage.getItem("token")) {
    generalContext.token = JSON.parse(localStorage.getItem("token")!);
  }

  if (!generalContext.userName && !!localStorage.getItem("username")) {
    generalContext.userName = JSON.parse(localStorage.getItem("username")!);
  }

  Hub.listen("auth", async ({ payload }) => {
    setGeneralContext({ ...generalContext, loading: true });
    switch (payload.event) {
      case "signedIn":
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        localStorage.setItem("token", JSON.stringify(idToken?.toString()));
        localStorage.setItem("username", JSON.stringify(payload.data.username));
        setGeneralContext({
          ...generalContext,
          userName: payload.data.username,
          token: idToken?.toString(),
          loading: false,
        });
        break;
      case "signedOut":
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        setGeneralContext({
          ...generalContext,
          loading: false,
          userName: undefined,
          token: undefined,
        });
        break;
    }
  });

  return route === "authenticated" ? (
    <AppRouter />
  ) : (
    <div className="amplify-login">
      <LoginAuthenticator />
    </div>
  );

  // return (
  //   <div>
  //     {generalContext && !generalContext.token ? <Login /> : <RouterIfLogin />}
  //   </div>
  // );
}

const AppRouter = () => {
  return <Dashboard />;
};

const LoginAuthenticator = () => (
  <div className="flex min-h-full flex-1 flex-col justify-center py-16 sm:px-6 lg:px-8">
    <Authenticator
      hideSignUp
      components={{
        Header() {
          return (
            <div className="pl-32 mx-auto h-10 w-auto  justify-center mb-4">
              <WorxIcon />
            </div>
          );
        },
      }}
    />
  </div>
);
