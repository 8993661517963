import React from "react";

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.43 10.98C17.47 10.66 17.5 10.34 17.5 10C17.5 9.66 17.47 9.34 17.43 9.02L19.54 7.37C19.73 7.22 19.78 6.95 19.66 6.73L17.66 3.27C17.54 3.05 17.27 2.97 17.05 3.05L14.56 4.05C14.04 3.65 13.48 3.32 12.87 3.07L12.49 0.42C12.46 0.18 12.25 0 12 0H8.00002C7.75002 0 7.54002 0.18 7.51002 0.42L7.13002 3.07C6.52002 3.32 5.96002 3.66 5.44002 4.05L2.95002 3.05C2.72002 2.96 2.46002 3.05 2.34002 3.27L0.340022 6.73C0.210022 6.95 0.270023 7.22 0.460023 7.37L2.57002 9.02C2.53002 9.34 2.50002 9.67 2.50002 10C2.50002 10.33 2.53002 10.66 2.57002 10.98L0.460023 12.63C0.270023 12.78 0.220022 13.05 0.340022 13.27L2.34002 16.73C2.46002 16.95 2.73002 17.03 2.95002 16.95L5.44002 15.95C5.96002 16.35 6.52002 16.68 7.13002 16.93L7.51002 19.58C7.54002 19.82 7.75002 20 8.00002 20H12C12.25 20 12.46 19.82 12.49 19.58L12.87 16.93C13.48 16.68 14.04 16.34 14.56 15.95L17.05 16.95C17.28 17.04 17.54 16.95 17.66 16.73L19.66 13.27C19.78 13.05 19.73 12.78 19.54 12.63L17.43 10.98ZM10 13.5C8.07002 13.5 6.50002 11.93 6.50002 10C6.50002 8.07 8.07002 6.5 10 6.5C11.93 6.5 13.5 8.07 13.5 10C13.5 11.93 11.93 13.5 10 13.5Z"
      fill="#4686FA"
    />
  </svg>
);
