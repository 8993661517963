import axios from "axios";
import env from "../config/api";
import {
  AdminCreateChangePasswordRequest,
  AdminCreateUserRequest,
  ConfirmSignup,
  LoginRequest,
} from "../types/cognito";
import { CreateContext } from "../context/App";

export const signIn = async (loginRequest: LoginRequest) => {
  return await axios.post(`${env.cognitoBaseDomain}auth/signin`, loginRequest);
};

export const signOut = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("username");
};

export const adminCreateUser = async (
  adminCreateUserRequest: AdminCreateUserRequest,
  createContext: CreateContext
) => {
  const { generalContext, setGeneralContext } = createContext;
  try {
    setGeneralContext({ ...generalContext, loading: true });
    const result = await axios.post(
      `${env.cognitoBaseDomain}auth/admin-create-user`,
      adminCreateUserRequest,
      {
        headers: {
          authorization: `token=${createContext.generalContext.token}`,
        },
      }
    );
    setGeneralContext({
      ...generalContext,
      loading: false,
    });
  } catch (e: any) {
    alert(JSON.stringify(e));
    setGeneralContext({
      ...generalContext,
      userName: undefined,
      token: undefined,
      loading: false,
    });
  }
};

export const adminCreateChangePassword = async (
  adminCreateChangePasswordRequest: AdminCreateChangePasswordRequest,
  createContext: CreateContext
) => {
  const { generalContext, setGeneralContext } = createContext;
  try {
    setGeneralContext({ ...generalContext, loading: true });
    const result = await axios.post(
      `${env.cognitoBaseDomain}auth/change-password`,
      adminCreateChangePasswordRequest
    );
    setGeneralContext({
      ...generalContext,
      token: result.data.message.AuthenticationResult.IdToken,
      userName: adminCreateChangePasswordRequest.username,
      loading: false,
    });
    localStorage.setItem("token", JSON.stringify(result.data.message.AuthenticationResult.IdToken));
    localStorage.setItem("username", JSON.stringify(adminCreateChangePasswordRequest.username));
  } catch (e: any) {
    alert(JSON.stringify(e));
    setGeneralContext({
      ...generalContext,
      userName: undefined,
      token: undefined,
      loading: false,
    });
  }
};

export const confirmSignup = async (
  confirmSignup: ConfirmSignup,
  createContext: CreateContext
) => {
  const { generalContext, setGeneralContext } = createContext;
  try {
    setGeneralContext({ ...generalContext, loading: true });
    const result = await axios.post(
      `${env.cognitoBaseDomain}auth/confirm-signup`,
      confirmSignup
    );
    setGeneralContext({
      ...generalContext,
      token: result.data.message.AuthenticationResult.IdToken,
      userName: confirmSignup.username,
      loading: false,
    });
    localStorage.setItem("token", JSON.stringify(result.data.message.AuthenticationResult.IdToken));
    localStorage.setItem("username", JSON.stringify(confirmSignup.username));
  } catch (e: any) {
    alert(JSON.stringify(e));
    setGeneralContext({
      ...generalContext,
      userName: undefined,
      token: undefined,
      loading: false,
    });
  }
};

// 12345678910Tagworx!
