import { useLayoutEffect, useMemo, useRef, useState } from "react";
import styles from "./styles";
import { IProps } from "./interface";
import {
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";

const statuses: any = {
  Active: "text-green-700 bg-green-50 ring-green-600/20",
  Suspended: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Deleted: "text-red-700 bg-red-50 ring-red-600/10",
};

const columns = [
  {
    header: "Company Name",
    accessorKey: "companyName",
    cell: (info: any) => (
      <div className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900">
        {info.getValue()}
      </div>
    ),
  },
  {
    header: "Status",
    accessorKey: "status",
    cell: (info: any) => (
      <div className="flex">
        <div
          className={`${
            statuses[info.getValue() || "Active"]
          } rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset`}
        >
          {info.getValue() ?? "Active"}
        </div>
      </div>
    ),
  },
  {
    header: "Email",
    accessorKey: "email",
    cell: (info: any) => (
      <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
        {info.getValue()}
      </div>
    ),
  },
  {
    header: "Applications",
    accessorKey: "applications",
    cell: (info: any) =>
      info.getValue() ? (
        <ul className="flex gap-1 max-w-xs flex-wrap py-2">
          {info.getValue().map((item: any) => (
            <li className="text-indigo-700 bg-indigo-50 ring-indigo-500/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset">
              {item}
            </li>
          ))}
        </ul>
      ) : (
        "-"
      ),
  },
  {
    header: "Max Users",
    accessorKey: "maxUsers",
    cell: (info: any) => (
      <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
        {info.getValue()}
      </div>
    ),
  },
  {
    header: "Created At",
    accessorKey: "inventory.createdAt",
    cell: (info: any) => (
      <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
        {info.getValue()
          ? new Date(info.getValue()).toLocaleDateString("en-US")
          : "-"}
      </div>
    ),
  },
  {
    header: "Updated At",
    accessorKey: "inventory.updatedAt",
    cell: (info: any) => (
      <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
        {info.getValue()
          ? new Date(info.getValue()).toLocaleDateString("en-US")
          : "-"}
      </div>
    ),
  },
  {
    header: "Inventory Sum",
    accessorKey: "inventory.sum",
    cell: (info: any) => (
      <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
        {info.getValue()}
      </div>
    ),
  },
  {
    header: "Product Created At",
    accessorKey: "product.createdAt",
    cell: (info: any) => (
      <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
        {info.getValue()
          ? new Date(info.getValue()).toLocaleDateString("en-US")
          : "-"}
      </div>
    ),
  },
  {
    header: "Product Updated At",
    accessorKey: "product.updatedAt",
    cell: (info: any) => (
      <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
        {info.getValue()
          ? new Date(info.getValue()).toLocaleDateString("en-US")
          : "-"}
      </div>
    ),
  },
  {
    header: "Product Sum",
    accessorKey: "product.sum",
    cell: (info: any) => (
      <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
        {info.getValue()}
      </div>
    ),
  },
];

const Table = ({
  tenantTable,
  onDeleteTenants,
  onActivateTenants,
  onSuspendTenants,
  onClickRow,
}: IProps | any) => {
  const [sorting, setSorting] = useState<any>([]);

  const checkbox = useRef<any>(null);
  const [checked, setChecked] = useState(false);
  const [selectedTenants, setSelectedTenants] = useState<string[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);

  const data = useMemo(() => tenantTable?.tenants, [tenantTable]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  });

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedTenants.length > 0 &&
      selectedTenants.length < tenantTable?.tenants.length;
    setChecked(selectedTenants.length === tenantTable?.tenants.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedTenants]);

  function toggleAll() {
    setSelectedTenants(
      checked || indeterminate
        ? []
        : tenantTable?.tenants.map((_: any, i: number) => String(i))
    );
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  return (
    <>
      <div className="mt-0 flow-root">
        <div className="-mx-4 mt-0 ring-1 overflow-x-auto overflow-scroll ring-gray-300 sm:mx-0 sm:rounded-lg">
          <div className="inline-block min-w-full py-2 align-middle sm:px-0 lg:px-0">
            <div className="relative">
              {selectedTenants.length > 0 && (
                <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                  <button
                    type="button"
                    onClick={() =>
                      onActivateTenants(
                        tenantTable?.tenants.filter((_: any, i: number) =>
                          selectedTenants.indexOf(String(i)) !== -1
                            ? true
                            : false
                        )
                      )
                    }
                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  >
                    {selectedTenants.length > 1
                      ? "Activate all"
                      : "Activate tenant"}
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      onSuspendTenants(
                        tenantTable?.tenants.filter((_: any, i: number) =>
                          selectedTenants.indexOf(String(i)) !== -1
                            ? true
                            : false
                        )
                      )
                    }
                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  >
                    {selectedTenants.length > 1
                      ? "Suspend all"
                      : "Suspend tenant"}
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      onDeleteTenants(
                        tenantTable?.tenants.filter((_: any, i: number) =>
                          selectedTenants.indexOf(String(i)) !== -1
                            ? true
                            : false
                        )
                      )
                    }
                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  >
                    {selectedTenants.length > 1
                      ? "Delete all"
                      : "Delete tenant"}
                  </button>
                </div>
              )}
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          ref={checkbox}
                          checked={checked}
                          onChange={toggleAll}
                        />
                      </th>
                      {headerGroup.headers.map((header, index) => {
                        return (
                          <th
                            className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                            key={header.id}
                            colSpan={header.colSpan}
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? "flex pic"
                                    : "flex",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: (
                                    <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                                      <ChevronDownIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ),
                                  desc: (
                                    <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                                      <ChevronUpIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ),
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {table.getRowModel().rows.map((row, index) => (
                    <tr
                      key={row.id}
                      className={
                        selectedTenants.includes(row.id)
                          ? "bg-blue-50"
                          : `${index % 2 === 0 ? "" : "bg-gray-50"}`
                      }
                    >
                      <td className="relative px-7 sm:w-12 sm:px-6">
                        {selectedTenants.includes(row.id) && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                        )}
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={row.id}
                          checked={selectedTenants.includes(row.id)}
                          onChange={(e) =>
                            setSelectedTenants(
                              e.target.checked
                                ? [...selectedTenants, row.id]
                                : selectedTenants.filter((r) => r !== row.id)
                            )
                          }
                        />
                      </td>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          onClick={() => onClickRow(row.original)}
                          key={cell.id}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <style jsx={true}>{styles}</style>
            </div>
          </div>
        </div>

        {/* PAGINATION */}
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="flex flex-1 justify-between sm:hidden">
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing page{" "}
                <span className="font-medium">
                  {table.getState().pagination.pageIndex + 1}
                </span>{" "}
                of <span className="font-medium">{table.getPageCount()}</span>
              </p>
            </div>
            <div>
              <nav
                className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                aria-label="Pagination"
              >
                <button
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                {new Array(table.getPageCount())
                  .fill(0)
                  .map((_: any, pageIndex) => (
                    <button
                      onClick={() => {
                        table.setPageIndex(pageIndex);
                      }}
                      aria-current="page"
                      className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20 focus-visible:outline   ${
                        table.getState().pagination.pageIndex === pageIndex
                          ? "z-10 bg-indigo-600 text-white focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          : " text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                      }`}
                    >
                      {pageIndex + 1}
                    </button>
                  ))}
                <button
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </div>
        {/* ---- */}
      </div>
    </>
  );
};

export default Table;
