import { createContext, useState } from "react";
import { CreateTenantRequest, TenantTable } from "../types/tenant";
import { createTenant, getTenant, getTenants } from "../services/tenant";
import { signOut } from "../services/cognito";
interface UserContext {
  userName?: string;
  token?: string;
}

interface TenantTableContext {
  getTenantsAsync: (
    generalContext: AppContext,
    setGeneralContext: React.Dispatch<React.SetStateAction<AppContext>>
  ) => Promise<void>;
  getTenantAsync: (
    generalContext: AppContext,
    setGeneralContext: React.Dispatch<React.SetStateAction<AppContext>>,
    tenantId: string
  ) => Promise<void>;
  createTenantAsync: (
    generalContext: AppContext,
    setGeneralContext: React.Dispatch<React.SetStateAction<AppContext>>,
    createTenantRequest: CreateTenantRequest
  ) => Promise<void>;
  tenantTable: TenantTable;
  tenantDetail: CreateTenantRequest;
}

interface AppContext extends UserContext, TenantTableContext {
  loading?: boolean;
  error?: string;
}

export interface CreateContext {
  generalContext: AppContext;
  setGeneralContext: React.Dispatch<React.SetStateAction<AppContext>>;
}

const userContext = {
  userName: undefined,
  token: undefined,
};

const appContext = {
  loading: false,
  error: undefined,
};

const tenantsContext = {
  getTenantsAsync: async (
    generalContext: AppContext,
    setGeneralContext: React.Dispatch<React.SetStateAction<AppContext>>
  ) => {
    setGeneralContext({ ...generalContext, loading: true });
    try {
      const result = await getTenants(generalContext.token!);
      setGeneralContext({
        ...generalContext,
        loading: false,
        tenantTable: result.data,
      });
    } catch (e: any) {
      if (e.code === "ERR_NETWORK") {
        setGeneralContext({
          ...generalContext,
          loading: false,
          userName: undefined,
          token: undefined,
        });
        signOut();
      }
    }
  },
  getTenantAsync: async (
    generalContext: AppContext,
    setGeneralContext: React.Dispatch<React.SetStateAction<AppContext>>,
    tenantId: string
  ) => {
    setGeneralContext({ ...generalContext, loading: true });
    try {
      const result = await getTenant(generalContext.token!, tenantId);
      setGeneralContext({
        ...generalContext,
        loading: false,
        tenantDetail: result.data,
      });
    } catch (e: any) {
      if (e.response && e.response.status === 403) {
        setGeneralContext({
          ...generalContext,
          loading: false,
          userName: undefined,
          token: undefined,
        });
        signOut();
      }
    }
  },
  createTenantAsync: async (
    generalContext: AppContext,
    setGeneralContext: React.Dispatch<React.SetStateAction<AppContext>>,
    createTenantRequest: CreateTenantRequest
  ) => {
    setGeneralContext({ ...generalContext, loading: true });
    try {
      console.log({ createTenantRequest });
      const resCreateTenant = await createTenant(
        createTenantRequest,
        generalContext.token!
      );
      console.log({ resCreateTenant });
      setGeneralContext({
        ...generalContext,
        loading: false,
        tenantDetail: {} as CreateTenantRequest,
      });
    } catch (e: any) {
      if (e.response && e.response.status === 403) {
        setGeneralContext({
          ...generalContext,
          loading: false,
          userName: undefined,
          token: undefined,
        });
        signOut();
      }
    }
  },
  tenantTable: {
    tenants: [],
    page: 0,
    totalTenants: 0,
  },
  tenantDetail: {} as CreateTenantRequest,
};

export const generalContextInitialValue: AppContext = {
  ...userContext,
  ...appContext,
  ...tenantsContext,
};

export const GeneralContext = createContext({} as CreateContext);

export const GeneralContextProvider = ({
  generalContextInitialValue,
  children,
}: any) => {
  const [generalContext, setGeneralContext] = useState(
    generalContextInitialValue
  );
  return (
    <GeneralContext.Provider value={{ generalContext, setGeneralContext }}>
      {children}
    </GeneralContext.Provider>
  );
};

// const {generalContext, setGeneralContext} = useContext(GeneralContext)
