import { TenantType } from "../types/tenant";

const defaultTenant: TenantType = {
    companyName: "tagworx",
    applications: [],
    email: "tagworx@worx.software",
    maxUsers: 10,
    product: { sum: 0 },
    inventory: {
      createdAt: "2023-11-23T12:33:46.483Z",
      sum: 36,
      updatedAt: "2023-11-23T12:33:46.483Z",
    },
    status: "Active",
    _id: "0"
};

export {defaultTenant}