import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="26"
    viewBox="0 0 50 42.058"
  >
    <path
      fill="#289cff"
      d="M20.373,32.335l-4.412,5.32h6.483l4.427-5.32Z"
      transform="translate(-7.632 4.404)"
    />
    <path
      fill="#289cff"
      d="M36.071,21.206l4.4-5.32H33.994l-4.4,5.32Z"
      transform="translate(9.119 -15.887)"
    />
    <path
      fill="#fff"
      d="M57.191,57.944H50.83L36.377,40.68,30.75,47.429H24.39L33.2,36.771,16.175,15.887h6.361L36.377,32.948,41.761,26.4h6.358L39.559,36.771Z"
      transform="translate(-7.191 -15.886)"
    />
    <g transform="translate(0 11.267)">
      <path
        fill="#289cff"
        d="M25.543,25.53l-4.382-.036v9.469l-4.546,5.483V25.53H12.224V20.987H25.543Z"
        transform="translate(-12.224 -20.987)"
      />
    </g>
  </svg>
);
