import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import InputWithLabel from "./InputWithLabel";
import InputDropdown from "./InputDropdown";
import Datepicker from "tailwind-datepicker-react";
import { IOptions } from "tailwind-datepicker-react/types/Options";
import { IAdminUser } from "../services/users";

type IDefaultFields = {
  username?: { show: boolean; disabled: boolean };
  firstName?: { show: boolean; disabled: boolean };
  lastName?: { show: boolean; disabled: boolean };
  email?: { show: boolean; disabled: boolean };
  mobile?: { show: boolean; disabled: boolean };
  role?: { show: boolean; disabled: boolean };
  expirationDate?: { show: boolean; disabled: boolean };
  activationDate?: { show: boolean; disabled: boolean };
};

export type IUserFormData = {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  mobile?: string;
  role: { id: number; name: string; value: string } | null;
  expirationDate?: string;
  activationDate?: string;
};

export type ISuperUserSubmit = {
  role: string;
  username: string;
  email: string;
};

const defaultFields: IDefaultFields = {
  username: { show: true, disabled: false },
  firstName: { show: true, disabled: false },
  lastName: { show: true, disabled: false },
  email: { show: true, disabled: false },
  mobile: { show: true, disabled: false },
  role: { show: true, disabled: false },
  expirationDate: { show: true, disabled: false },
  activationDate: { show: true, disabled: false },
};

export default function SlideUserForm({
  data,
  title = "Add User",
  actionLabel = "Send Invitation",
  open,
  onClose,
  fields = defaultFields,
  onSubmit,
}: {
  data?: IAdminUser;
  title?: string;
  actionLabel?: string;
  open: boolean;
  onClose: () => void;
  fields?: IDefaultFields;
  onSubmit?: (selectedFormFieldsData: any) => void;
}) {
  const [formData, setFormData] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    role: { id: 1, name: "Superadmin", value: "super" },
    expirationDate: null,
    activationDate: null,
  });

  useEffect(() => {
    if (data) {
      setFormData((prev) => ({
        ...prev,
        username: data.username,
        email: data.email,
      }));
    } else {
      resetFormData();
    }
  }, [data]);

  const resetFormData = () =>
    setFormData({
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      role: { id: 1, name: "Superadmin", value: "super" },
      expirationDate: null,
      activationDate: null,
    });

  const handleDataChange = (data: any) => {
    setFormData(data);
  };

  const handleSendInvitation = () => {
    const selectedFormData: any = {};

    (Object.keys(fields) as Array<keyof IDefaultFields>).forEach((key) => {
      const fieldValue = formData[key];
      if (key === "role" && fieldValue) {
        selectedFormData[key] = (fieldValue as any).value;
      } else {
        selectedFormData[key] = fieldValue as any;
      }
    });

    if (onSubmit) {
      onSubmit(
        selectedFormData as { role: string; username: string; email: string }
      );
      resetFormData();
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            {title}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              onClick={() => onClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <UserForm
                          formData={formData}
                          fields={fields}
                          onChange={handleDataChange}
                        />
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                        onClick={() => onClose()}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={handleSendInvitation}
                        className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        {actionLabel}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const UserForm = ({
  fields,
  onChange,
  formData,
}: {
  fields: IDefaultFields;
  onChange: (data: any) => void;
  formData: any;
}) => {
  const roleOptions = [
    { id: 1, name: "Superadmin", value: "super" },
    { id: 2, name: "Reseller", value: "reseller" },
  ];
  const options: IOptions = {
    autoHide: true,
    todayBtn: false,
    clearBtn: true,
    clearBtnText: "Clear",
    maxDate: new Date("2030-01-01"),
    minDate: new Date("1950-01-01"),
    icons: {
      prev: () => <span>Previous</span>,
      next: () => <span>Next</span>,
    },
    datepickerClassNames: "top-12",
    defaultDate: new Date("2022-01-01"),
    language: "en",
    disabledDates: [],
    weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Select Date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  };

  const [showDateExpiry, setShowDateExpiry] = useState<boolean>(false);
  const [showDateActivation, setShowDateActivation] = useState<boolean>(false);

  const handleChange = (name: string, value: any) => {
    const newFormData = { ...formData, [name]: value };
    onChange(newFormData);
  };

  return (
    <>
      {fields.username?.show && (
        <InputWithLabel
          disabled={fields.username?.disabled}
          label="Username"
          value={formData.username}
          id="username"
          type="text"
          placeholder="Username"
          onChange={(e: any) => handleChange("username", e.target.value)}
        />
      )}
      {fields.firstName?.show && (
        <InputWithLabel
          disabled={fields.firstName?.disabled}
          label="First name"
          value={formData.firstName}
          id="first-name"
          type="text"
          placeholder="John"
          onChange={(e: any) => handleChange("firstName", e.target.value)}
        />
      )}
      {fields.lastName?.show && (
        <InputWithLabel
          disabled={fields.lastName?.disabled}
          label="Last name"
          value={formData.lastName}
          id="last-name"
          type="text"
          placeholder="Doe"
          onChange={(e: any) => handleChange("lastName", e.target.value)}
        />
      )}
      {fields.email?.show && (
        <InputWithLabel
          disabled={fields.email?.disabled}
          label="Email"
          value={formData.email}
          id="email"
          type="email"
          placeholder="johndoe@lab.com"
          onChange={(e: any) => handleChange("email", e.target.value)}
        />
      )}
      {fields.mobile?.show && (
        <InputWithLabel
          disabled={fields.mobile?.disabled}
          label="Mobile"
          value={formData.mobile}
          id="mobile"
          type="tel"
          placeholder="07123 456 789"
          onChange={(e: any) => handleChange("mobile", e.target.value)}
        />
      )}
      {fields.role?.show && (
        <>
          <div className="mt-5" />
          <InputDropdown
            label="Role"
            options={roleOptions}
            selected={formData.role}
            onSelect={(option: { id: number; name: string }) =>
              handleChange("role", option)
            }
          />
          <div className="mt-5" />
        </>
      )}
      {fields.expirationDate?.show && (
        <>
          <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
            Expiration Date
          </label>
          <Datepicker
            options={{ ...options, title: "Expiration Date" }}
            onChange={(selectedDate) =>
              handleChange("expirationDate", selectedDate)
            }
            value={formData.expirationDate}
            show={showDateExpiry}
            setShow={setShowDateExpiry}
          />
          <div className="mt-5" />
        </>
      )}
      {fields.activationDate?.show && (
        <>
          <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
            Activation Date
          </label>
          <Datepicker
            options={{ ...options, title: "Activation Date" }}
            value={formData.activationDate}
            onChange={(selectedDate) =>
              handleChange("activationDate", selectedDate)
            }
            show={showDateActivation}
            setShow={setShowDateActivation}
          />
        </>
      )}
    </>
  );
};
