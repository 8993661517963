import { useContext, useEffect } from "react";
import { GeneralContext } from "../context/App";
import TenantTable from "../components/TenantTable/TenantTable";
import Loader from "../components/Loader";
import { useQuery } from "@tanstack/react-query";

import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  Cog6ToothIcon,
  HomeIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { TagWorxIcon } from "../icons";
import {
  deleteTenant,
  getTenants,
  updateTenantStatus,
} from "../services/tenant";
import SectionHeading from "../components/SectionHeading";
import SlideCreateTenant from "../components/SlideCreateTenant";
import SlideTenantDetail from "../components/SlideTenantDetail";
import { defaultTenant } from "../utils/mockData";
import { TenantType } from "../types/tenant";
import { useAuthenticator } from "@aws-amplify/ui-react";
import ChangePasswordModal from "../components/ChangePassword";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Tabs from "../components/Tabs";
import UsersTable from "../components/UsersTable";
import SlideUserForm, { ISuperUserSubmit } from "../components/SlideUserForm";
import {
  IAdminUser,
  deleteAdminUser,
  getAdminUsers,
  sendInvitationNewAdminUser,
  updateAdminUser,
} from "../services/users";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showCreateSlide, setShowCreateSlide] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [selectedTenant, setSelectedTenants] = useState<undefined | TenantType>(
    undefined
  );
  const { generalContext, setGeneralContext } = useContext(GeneralContext);
  const location = useLocation();
  const userNavigation = [
    {
      name: "Change Password",
      href: "#",
      onClick: () => setShowChangePasswordModal(true),
    },
    { name: "Sign out", href: "/", onClick: () => handleLogout() },
  ];

  const sidebarRoutes = [
    {
      path: "/",
      main: () => (
        <div className="px-4 sm:px-6 lg:px-8 py-8">
          <SectionHeading
            heading="Tenants List"
            onButtonClick={() => setShowCreateSlide(true)}
          />
          {tenantsData?.status === 200 && (
            <TenantTable
              tenantTable={tenantsData?.data}
              onActivateTenants={handleActivateTenants}
              onSuspendTenants={handleSuspendTenants}
              onDeleteTenants={handleDeleteTenants}
              onClickRow={handleSelectTenant}
            />
          )}
        </div>
      ),
    },
    { path: "/settings", main: () => <SettingsScreen /> },
  ];

  const {
    data: tenantsData,
    isLoading: tenantsLoading,
    refetch,
  } = useQuery({
    queryKey: ["get-tenants"],
    queryFn: () => getTenants(generalContext.token ?? ""),
  });

  const handleLogout = () => {
    setGeneralContext({
      ...generalContext,
      loading: false,
      userName: undefined,
      token: undefined,
    });
    signOut();
  };

  const handleActivateTenants = async (tenants: any[]) => {
    try {
      tenants.forEach(async (tenant) => {
        if (!generalContext.token) return;
        await updateTenantStatus(tenant._id, generalContext.token, "Active");
      });
      setTimeout(async () => {
        await refetch();
        alert("Tenants activated successfully");
      }, 1000);
    } catch (error) {
      alert("Unable to activate tenants");
    }
  };

  const handleSuspendTenants = async (tenants: any[]) => {
    try {
      tenants.forEach(async (tenant) => {
        if (!generalContext.token) return;
        await updateTenantStatus(tenant._id, generalContext.token, "Suspended");
      });
      setTimeout(async () => {
        await refetch();
        alert("Tenants suspended successfully");
      }, 1000);
    } catch (error) {
      alert("Unable to suspend all tenants");
    }
  };

  const handleDeleteTenants = async (tenants: any[]) => {
    try {
      tenants.forEach(async (tenant) => {
        if (!generalContext.token) return;
        await deleteTenant(tenant._id, generalContext.token);
      });
      setTimeout(async () => {
        await refetch();
        alert("Tenants suspended successfully");
      }, 1000);
    } catch (error) {
      alert("Unable to delete all tenants");
    }
  };

  const handleSelectTenant = (tenant: TenantType) => {
    console.log(tenant);
    setSelectedTenants(tenant);
  };

  if (tenantsLoading) return <Loader />;

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            <li key={"tenants"}>
                              <Link
                                to={"/"}
                                className={classNames(
                                  location.pathname === "/"
                                    ? "bg-gray-800 text-white"
                                    : "text-gray-400 hover:text-white hover:bg-gray-800",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <HomeIcon
                                  className="h-6 w-6 shrink-0"
                                  aria-hidden="true"
                                />
                                {"Tenants"}
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <Link
                            to={"/settings"}
                            className={classNames(
                              location.pathname === "/settings"
                                ? "bg-gray-800 text-white"
                                : "text-gray-400 hover:text-white hover:bg-gray-800",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )}
                          >
                            {/* <a
                            href="/"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                          > */}
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            Settings
                            {/* </a> */}
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <TagWorxIcon />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {/* {navigation.map((item) => ( */}
                    <li key={"tenants"}>
                      <Link
                        to={"/"}
                        className={classNames(
                          location.pathname === "/"
                            ? "bg-gray-800 text-white"
                            : "text-gray-400 hover:text-white hover:bg-gray-800",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <HomeIcon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {"Tenants"}
                      </Link>
                    </li>
                    {/* ))} */}
                  </ul>
                </li>
                <li className="mt-auto">
                  <Link
                    to="/settings"
                    className={classNames(
                      location.pathname === "/settings"
                        ? "bg-gray-800 text-white"
                        : "text-gray-400 hover:text-white hover:bg-gray-800",
                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    )}
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    Settings
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-60">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="w-full flex items-center justify-end gap-x-4 lg:gap-x-6">
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                        aria-hidden="true"
                      >
                        {generalContext.userName}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              onClick={item.onClick}
                              to={item.href}
                              className={classNames(
                                active ? "bg-gray-50" : "",
                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main>
            <Routes>
              {sidebarRoutes.map(({ path, main }) => (
                <Route key={path} path={path} element={main()} />
              ))}
            </Routes>
            {/* <SectionHeading onButtonClick={() => setShowCreateSlide(true)} />
              {tenantsData?.status === 200 && (
                <TenantTable
                  tenantTable={tenantsData?.data}
                  onSuspendTenants={handleSuspendTenants}
                  onDeleteTenants={handleDeleteTenants}
                  onClickRow={handleSelectTenant}
                />
              )} */}
          </main>
        </div>
      </div>
      <SlideCreateTenant
        open={showCreateSlide}
        onClose={() => setShowCreateSlide(false)}
      />
      <SlideTenantDetail
        open={selectedTenant !== undefined}
        onClose={() => {
          setSelectedTenants(undefined);
        }}
        tenant={selectedTenant || defaultTenant}
      />
      <ChangePasswordModal
        open={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(false)}
      />
    </>
  );
}

const SettingsScreen = () => {
  const [tabs, setTabs] = useState([
    { name: "Admin Users", current: true, href: "#" },
  ]);
  const [editModeEnabled, setEditModeEnabled] = useState<null | IAdminUser>(
    null
  );
  const [showUserForm, setShowUserForm] = useState(false);
  const { generalContext, setGeneralContext } = useContext(GeneralContext);

  const {
    data: adminUsers,
    isError,
    isLoading: adminUsersLoading,
    refetch,
  } = useQuery({
    queryKey: ["get-admin-users"],
    queryFn: () => getAdminUsers(generalContext.token ?? ""),
  });

  useEffect(() => {
    if (isError) alert("Fetch error");
  }, [isError]);

  const handleSubmitSuperUserInvitation = async (
    formData: ISuperUserSubmit
  ) => {
    try {
      await sendInvitationNewAdminUser(generalContext.token ?? "", formData);
      alert("Invitation sent successfully");
      refetch();
    } catch (error) {
      alert("Unable to send the user invitation. Try later.");
    }
  };

  const handleEditUser = (user: IAdminUser) => {
    setEditModeEnabled(user);
    setShowUserForm(true);
  };

  const handleSubmitUserEdit = async (data: any) => {
    try {
      if (!editModeEnabled?.username)
        alert("Unable to update the user role. Try later.");

      await updateAdminUser(
        generalContext.token ?? "",
        editModeEnabled?.username!,
        {
          role: data.role,
        }
      );
      setEditModeEnabled(null);
      setShowUserForm(false);
      alert("Role updated successfully");
      refetch();
    } catch (error) {
      alert("Unable to update the user role. Try later.");
    }
  };

  const handleDeleteUser = async (username: string) => {
    try {
      await deleteAdminUser(generalContext.token ?? "", username);
      alert(`User ${username} has been deleted.`);
      refetch();
    } catch (error) {
      alert("Unable to delete user. Try later.");
    }
  };

  return (
    <div>
      <Tabs tabs={tabs} onSelect={() => {}} />
      <div className="px-4 sm:px-6 lg:px-8 mt-5">
        {tabs.find((tab) => tab.name === "Admin Users" && tab.current) && (
          <UsersTable
            onEditUser={handleEditUser}
            onDeleteUser={handleDeleteUser}
            title="Admin Users"
            subTitle="Manage users access to admin."
            onAddUser={() => setShowUserForm(true)}
            users={adminUsers?.data}
          />
        )}
      </div>
      <SlideUserForm
        data={editModeEnabled ?? undefined}
        title={editModeEnabled ? "Edit User" : "Add User"}
        actionLabel={editModeEnabled ? "Update" : "Send Invitation"}
        onSubmit={
          editModeEnabled
            ? handleSubmitUserEdit
            : handleSubmitSuperUserInvitation
        }
        fields={{
          username: { show: true, disabled: editModeEnabled != null },
          email: { show: true, disabled: editModeEnabled != null },
          role: { show: true, disabled: false },
        }}
        open={showUserForm}
        onClose={() => {
          setShowUserForm(false);
          setTimeout(() => {
            setEditModeEnabled(null);
          }, 500);
        }}
      />
    </div>
  );
};
