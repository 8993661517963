import css from "styled-jsx/css";
import { theme } from "../../config/theme";

const {
  colors: { Blue_3, MayaBlue, Blue_2, Blue_4 },
} = theme;

const styles = css`
  .user-admin-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
  }

  .user-admin-table__header {
    background-color: ${Blue_3};
    // font-family: Roboto;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: white;
    text-transform: capitalize;
  }

  .user-admin-table__header th {
    padding: 15px 5px;
    border: none;
    text-align: center;
  }
  .user-admin-table__header th:first-child {
    padding-left: 54px;
    border-radius: 4px 0px 0px 0px;
    text-align: left;
  }
  .user-admin-table__header th:nth-child(2) {
    text-align: left;
    padding-left: 24px;
  }
  .user-admin-table__header th:nth-child(3) {
    text-align: left;
    padding-left: 24px;
  }
  .user-admin-table__header th:last-child {
    padding-right: 19px;
    border-radius: 0px 4px 0px 0px;
  }

  .user-admin-table__header th.user-admin-table__header__controls {
    text-align: right;
  }

  .user-admin-table__header__sort-btn {
    outline: none;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  .user-admin-table__header__sort-btn:first-child {
    // margin-left: 6px;
    margin-right: 3px;
  }

  .user-admin-table__row {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: black;
  }

  .user-admin-table__row:nth-child(odd) {
    background-color: ${Blue_2};
  }
  .user-admin-table__row td {
    padding: 8px 0;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .user-admin-table__row__link {
    color: inherit;
  }
  .user-admin-table__row td:first-child {
    padding-left: 50px;
    text-align: left;
  }
  .user-admin-table__row td:nth-child(2) {
    text-align: left;
  }
  .user-admin-table__row td:nth-child(3) {
    text-align: left;
  }
  .user-admin-table__row td:last-child {
    padding-right: 19px;
  }
  .user-admin-table__row__index {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
    text-align: right;
    text-decoration-line: underline;
    color: ${MayaBlue};
  }

  // Pagination
  .pagination {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: flex-end;
    margin: 25px;
  }
  .pagination_Btn {
    border-radius: 5px;
    padding: 4px;
    width: 35px;
    cursor: pointer;
    margin: 2px;
    background: #130050;
    color: #fff;
    height: 35px;
    font-weight:bold;
    border:none;
  }
  .pagination_Btn :hover{
    background: ${Blue_4};
  }
  .pagination_Btn:disabled {
    background: #656589;
    cursor: not-allowed;
  }
  .pagination_page {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .pagination_inp {
    border: 1px solid black;
    border-radius: 4px;
    width: 34px;
    outline: none;
    cursor: pointer;
    height: 30px;
    padding-left: 8px;
  }
  .pagination_inps {
    border: 1px solid black;
    border-radius: 4px;
    width: 95px;
    outline: none;
    cursor: pointer;
    height: 35px;
    padding: 0 5px;
  }
  .Btn {
    margin-left: 10px;
  }
  .pic {
    // position:absolute;
    cursor: pointer;
    select: none;
  }
`;

export default styles;
