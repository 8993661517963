const colors = {
  Comet: "#585D6E",
  PalePurple: "#B69EEC",
  MayaBlue: "#52C4F5",
  Grey_3: "#A5ADBA",

  Blue_1: "#4686FA",
  Blue_2: "#EDF3FF",
  Blue_3: "#041E4F",
  Blue_4: "#2066FF",
  Blue_5: "#EBF2FF",
  Blue_6: "#12004A",
  Blue_7: "#289CFF",
  Blue_8: "#4781ff",
  Gray_1: "#989CA3",
  Gray_2: "#E8EAED",
  Gray_3: "#FAFBFD",
  Gray_5: "#2A2F41",
  Grey_1: "#E8EAED",
  Grey_2: "#E6E6E6",
  Grey_4: "#707070",
  Grey_5: "#ACACAC",
  Green_1: "#2DC57C",
  Red_1: "#E34343",
  blue_4: "#9AB2DE",
  Green: "#27BB1C",
  Orange: "#FF8720",
  PastelOrange: "#ffe1af",
  DarkPastelOrange: "#dc6804",
  white: "#FFFFFF",
  black: "#000000",
  Blue_9: "#000029",
  Yellow: "#f8dc3e",
  Purple: "#c173fa",
  lowStock: "#DE1818",
  mediumStock: "#FFA500",
  highStock: "#23BA17",
};

export const theme = {
  colors,
};
